module.exports = [{
      plugin: require('C:/Projects.pm/ua.programmingmentor.com/node_modules/gatsby-plugin-layout/gatsby-browser'),
      options: {"plugins":[],"component":"C:\\Projects.pm\\ua.programmingmentor.com\\src\\layouts\\index.js"},
    },{
      plugin: require('C:/Projects.pm/ua.programmingmentor.com/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Projects.pm/ua.programmingmentor.com/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Projects.pm/ua.programmingmentor.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"..."},
    },{
      plugin: require('C:/Projects.pm/ua.programmingmentor.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
