module.exports = {
  siteTitle: "programming mentor", // <title>
  shortSiteTitle: "programming mentor - ти живий, поки вчишся", // <title> ending for posts and pages
  siteDescription: "Допомагаю розробникам зростати",
  siteUrl: "https://ua.programmingmentor.com",
  // pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",

  /* author */
  authorName: "greg lobinski",
  authorTwitterAccount: "greglobinski",

  /* info */
  headerTitle: "programming mentor",
  headerSubTitle: "ти живий, поки вчишся",

  /* manifest.json */
  manifestName: "programming mentor - ти живий, поки вчишся",
  manifestShortName: "pm", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "",

  // social
  authorSocialLinks: [
    { name: "github", url: "https://github.com/programmingmentor" },
    { name: "twitter", url: "https://twitter.com/koldovsky_com" },
    { name: "facebook", url: "https://www.facebook.com/programmingmentor" },
    { name: "instagram", url: "https://www.instagram.com/programmingmentor" }
  ]
};
